.App {
  text-align: center;
}

.tiltFont {
  font-family: 'Tilt Neon', cursive;
}

.purple {
  color: #4f3eed;
}